import { render, staticRenderFns } from "./jiaoguan.vue?vue&type=template&id=ded8703c&scoped=true&"
import script from "./jiaoguan.vue?vue&type=script&lang=js&"
export * from "./jiaoguan.vue?vue&type=script&lang=js&"
import style0 from "./jiaoguan.vue?vue&type=style&index=0&id=ded8703c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ded8703c",
  null
  
)

export default component.exports